import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Supplier } from "@kanpla/types";
import { Tooltip } from "antd";
import classnames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import SVG from "react-inlinesvg";
import { getAttribute } from "./getAttribute";

export type DisplayDescriptionProps = {
  allergenName: string;
  /** For example 'text-text-secondary' */
  colorClassName?: string;
  supplier?: Supplier;
  layout?: "horizontal" | "vertical";
  onlyIcon?: boolean;
  className?: string;
  size?: "small" | "normal" | "large";
  disabled?: boolean;
  /**
   * - Returns an SVG that is parsed via IMG tag only
   * - Issue: https://www.notion.so/kanpla/Menu-doesn-t-print-with-custom-allergenes-bac7c108d705405387f22b266d093284
   */
  disableSvg?: boolean;
  /** Different modes:
   * - Tag shows only attributes with displayMode tags (as labels)
   * - Description shows only attributes with displayMode description (as allergens)
   * - All shows all attributes (as allergens) */
  mode?: "description" | "tag" | "all";
};

export const DisplayAttribute = ({
  allergenName,
  colorClassName = "text-text-primary",
  supplier = null,
  layout = "horizontal",
  onlyIcon = false,
  className,
  size = "normal",
  disabled = false,
  disableSvg = false,
  mode = "description",
}: DisplayDescriptionProps) => {
  const { i18n } = useTranslation();

  const {
    name,
    type,
    background = "#64748b",
    faIcon,
  } = getAttribute({ supplier, getAll: true, key: allergenName, i18n });

  const customBrandingImage = supplier?.custom?.icons?.[type]?.[allergenName];
  const image = `/public/attributes/${allergenName}.svg`;

  const wrapperSizes = {
    small: "text-xs py-.5 px-1",
    normal: "text-sm py-1 px-1.5",
    large: "text-base py-1 px-2",
  };

  const iconSizes = {
    small: "w-4 h-4",
    normal: "w-5 h-5",
    large: "w-8 h-8",
  };

  const labelSizes = {
    small: "pl-1",
    normal: "pl-1.5",
    large: "pl-1.5",
  };

  const finalColor = mode === "tag" ? "text-white" : colorClassName;

  // Icon
  const imgContent = <img src={customBrandingImage || image} alt={name} />;
  const svgContent = (
    <SVG
      src={customBrandingImage || image}
      width={"100%"}
      height={"100%"}
      className={classnames(disabled ? "opacity-40" : "")}
    >
      <img src={customBrandingImage || image} alt={name} />
    </SVG>
  );

  const customIconContent = disableSvg ? imgContent : svgContent;
  const shouldUseFontAwesome = faIcon && !customBrandingImage;
  /**
   * We need to generate font awesome as font, when printing using puppeteer
   * https://github.com/puppeteer/puppeteer/issues/2556
   */
  const fontAwesomeIcon = shouldUseFontAwesome ? (
    disableSvg ? (
      <i className={classnames(`fa-${faIcon?.iconName}`, "fa-solid")} />
    ) : (
      <FontAwesomeIcon icon={faIcon} imageRendering="jpg" />
    )
  ) : null;

  return (
    <span
      key={allergenName}
      className={classnames(
        "inline-flex items-center justify-center",
        wrapperSizes[size],
        finalColor,
        layout === "vertical" && "flex-col",
        className
      )}
      style={{ background: mode === "tag" ? background : "" }}
    >
      <Tooltip title={onlyIcon && name}>
        <div className={classnames(disabled ? "opacity-40" : "", finalColor)}>
          {/* Keep SVG import, so the icons keep the right colors */}
          {shouldUseFontAwesome ? (
            fontAwesomeIcon
          ) : (
            <div className={classnames(iconSizes[size])}>
              {customIconContent}
            </div>
          )}
        </div>
      </Tooltip>
      {!onlyIcon && (
        <div
          className={classnames(
            "inline-block font-medium whitespace-nowrap",
            labelSizes[size]
          )}
        >
          {name}
        </div>
      )}
    </span>
  );
};
