import { ProductAllIcons, Supplier } from "@kanpla/types";
import classnames from "classnames";
import { isEmpty, sortBy } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { DisplayAttribute, DisplayDescriptionProps } from "./DisplayAttribute";
import { getAttributes } from "./getAttributes";
import { ProductSettingsHeader } from "../product/ProductSettingsHeader";
import classNames from "classnames";

interface Props {
  attributes: ProductAllIcons;
  hideHeader?: boolean;
  supplier?: Supplier;
  className?: string;
  size?: DisplayDescriptionProps["size"];
  mode?: DisplayDescriptionProps["mode"];
  disableSvg?: boolean;
  colorClassName?: string;
}

export const DisplayAttributes = ({
  attributes,
  hideHeader = true,
  supplier,
  className = "",
  size,
  mode,
  disableSvg,
  colorClassName = "",
}: Props) => {
  const { t, i18n } = useTranslation(["libs"]);

  const customAttributesData = getAttributes({
    supplier,
  });
  const activeAttributes: string[] = Object.entries(attributes || {})
    .filter(([key, used]) => {
      const isUsed = used;

      // Skip if no data
      const attributeData = customAttributesData.find((a) => a.key === key);
      if (!attributeData) return false;

      // Skip if hidden
      const attributeHidden = attributeData.frontendDisplayMode === "hidden";
      if (attributeHidden) return false;

      // Otherwise, determine based on mode
      const isMatchingMode =
        mode === "all" || attributeData.frontendDisplayMode === mode;
      return isUsed && isMatchingMode;
    })
    .map(([key]) => key);

  const noActiveAttributes = activeAttributes.length === 0;
  if (noActiveAttributes) return null;

  const sortedAttributes = sortBy(activeAttributes, (name) => name);
  const numberOfAttributes = activeAttributes.length;

  const tagWrapperStyle = classnames(
    "absolute top-0 right-0 flex overflow-hidden md:rounded-tr rounded-bl-md max-w-full",
    className
  );

  const descriptionWrapperStyle = classnames(
    "flex",
    hideHeader ? "py-2" : "px-2",
    "flex-wrap -mx-1",
    className
  );

  const shouldCollapse = numberOfAttributes > (isMobile ? 1 : 3);
  const isTagMode = mode === "tag";

  return (
    <div className={isTagMode ? tagWrapperStyle : descriptionWrapperStyle}>
      {!hideHeader && !isTagMode && (
        <ProductSettingsHeader
          title={isEmpty(i18n) ? "Allergener" : t("libs:allergens")}
          help={
            isEmpty(i18n)
              ? "Allergenerne er kun vejledende. Kontakt venligst køkkenet vedrørende spørgsmål om allergener."
              : t("libs:info-allergens")
          }
        />
      )}
      <div
        className={classNames(
          !isTagMode &&
            "rounded-md bg-background-secondary print:bg-transparent p-0.5 px-1 print:p-0 mt-1 flex-wrap",
          "flex print:inline-flex",
          className
        )}
      >
        {sortedAttributes.map((attributeKey) => {
          return (
            <DisplayAttribute
              key={attributeKey}
              allergenName={attributeKey}
              supplier={supplier}
              size={size}
              onlyIcon={isTagMode && shouldCollapse}
              mode={mode}
              disableSvg={disableSvg}
            />
          );
        })}
      </div>
    </div>
  );
};
