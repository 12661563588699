import {
  faCheeseSwiss,
  faCubesStacked,
  faEgg,
  faGarlic,
  faHandsHoldingHeart,
  faHourglassClock,
  faPepperHot,
  faTurkey,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faBird,
  faBowlRice,
  faCalendarDay,
  faCalendarWeek,
  faCauldron,
  faCow,
  faDeer,
  faDuck,
  faFish,
  faGlass,
  faGrillHot,
  faHeart,
  faLeaf,
  faLobster,
  faOlive,
  faOven,
  faPanFood,
  faPanFrying,
  faPeanuts,
  faPhone,
  faPig,
  faPotFood,
  faSeedling,
  faSheep,
  faSoap,
  faSparkles,
  faStar,
  faWheat,
  faWheatSlash,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { AttributeCustomization } from "@kanpla/types";

export interface AttributeData {
  key: string;
  /** Localized names by language code */
  names: {
    /** Danish name */
    da: string;
    /** English name */
    en: string;
  };
  /** Is active (default) */
  active: boolean;
  /** What data type is this (will be deprecated one day) */
  type: "allergens" | "labels" | "pictograms";
  /** How is this shown in backend (default) */
  backendInputCategory: AttributeCustomization["backendInputCategory"];
  /** How is this shown in frontend (default) */
  frontendDisplayMode: AttributeCustomization["frontendDisplayMode"];
  /** Background css gradient for labels */
  background?: string;
  /** HEX for labels icon color */
  color?: string;
  /** Optional FontAwesomeIcon icon, overwrites svg file */
  faIcon?: IconDefinition;
  name?: string;
}

export const attributesData: AttributeData[] = [
  {
    active: true,
    backendInputCategory: "allergener",
    frontendDisplayMode: "description",
    key: "wheat",
    names: {
      da: "Gluten",
      en: "Gluten",
    },
    type: "allergens",
    faIcon: faWheat,
  },
  {
    active: true,
    backendInputCategory: "allergener",
    frontendDisplayMode: "description",
    key: "celery",
    names: {
      da: "Selleri",
      en: "Celery",
    },
    type: "allergens",
  },
  {
    active: true,
    backendInputCategory: "allergener",
    frontendDisplayMode: "description",
    key: "crustaceans",
    names: {
      da: "Skaldyr",
      en: "Crustaceans",
    },
    type: "allergens",
    faIcon: faLobster,
  },
  {
    active: true,
    backendInputCategory: "allergener",
    frontendDisplayMode: "description",
    key: "egg",
    names: {
      da: "Æg",
      en: "Egg",
    },

    type: "allergens",
    faIcon: faEgg,
  },
  {
    active: true,
    backendInputCategory: "allergener",
    frontendDisplayMode: "description",
    key: "fish",
    names: {
      da: "Fisk",
      en: "Fish",
    },

    type: "allergens",
    faIcon: faFish,
  },
  {
    active: true,
    backendInputCategory: "allergener",
    frontendDisplayMode: "description",
    key: "lupin",
    names: {
      da: "Lupin",
      en: "Lupine",
    },

    type: "allergens",
  },
  {
    active: true,
    backendInputCategory: "allergener",
    frontendDisplayMode: "description",
    key: "milk",
    names: {
      da: "Laktose",
      en: "Lactose",
    },
    type: "allergens",
    faIcon: faGlass,
  },
  {
    active: true,
    backendInputCategory: "allergener",
    frontendDisplayMode: "description",
    key: "molluscs",
    names: {
      da: "Bløddyr",
      en: "Molluscs",
    },

    type: "allergens",
  },
  {
    active: true,
    backendInputCategory: "allergener",
    frontendDisplayMode: "description",
    key: "mustard",
    names: {
      da: "Sennep",
      en: "Mustard",
    },

    type: "allergens",
  },
  {
    active: true,
    backendInputCategory: "allergener",
    frontendDisplayMode: "description",
    key: "nuts",
    names: {
      da: "Nødder",
      en: "Nuts",
    },

    type: "allergens",
    faIcon: faPeanuts,
  },
  {
    active: true,
    backendInputCategory: "allergener",
    frontendDisplayMode: "description",
    key: "peanut",
    names: {
      da: "Jordnødder",
      en: "Peanuts",
    },

    type: "allergens",
  },
  {
    active: true,
    backendInputCategory: "allergener",
    frontendDisplayMode: "description",
    key: "pork",
    names: {
      da: "Svinekød",
      en: "Pork",
    },

    type: "allergens",
    faIcon: faPig,
  },
  {
    active: true,
    backendInputCategory: "allergener",
    frontendDisplayMode: "description",
    key: "sesame",
    names: {
      da: "Sesam",
      en: "Sesame",
    },

    type: "allergens",
  },
  {
    active: true,
    backendInputCategory: "allergener",
    frontendDisplayMode: "description",
    key: "soya",
    names: {
      da: "Soja",
      en: "Soy",
    },

    type: "allergens",
  },
  {
    active: true,
    backendInputCategory: "allergener",
    frontendDisplayMode: "description",
    key: "sulphurdioxide",
    names: {
      da: "Svovldioxid",
      en: "Sulfur dioxide",
    },

    type: "allergens",
  },
  {
    active: true,
    backendInputCategory: "labels",
    frontendDisplayMode: "tag",
    key: "vegan",
    names: {
      da: "Vegan",
      en: "Vegan",
    },
    type: "labels",
    background: `linear-gradient(107.57deg, #48BB78 -18.83%, #38A169 124.05%)`,
    color: "#48BB78",
    faIcon: faSeedling,
  },
  {
    active: true,
    backendInputCategory: "labels",
    frontendDisplayMode: "tag",
    key: "vegetarian",
    names: {
      da: "Vegetar",
      en: "Vegetarian",
    },

    type: "labels",
    background: `linear-gradient(107.57deg, #48BB78 -18.83%, #38A169 124.05%)`,
    color: "#48BB78",
    faIcon: faLeaf,
  },
  {
    active: true,
    backendInputCategory: "labels",
    frontendDisplayMode: "tag",
    key: "glutenFree",
    names: {
      da: "Glutenfri",
      en: "Gluten Free",
    },
    type: "labels",
    faIcon: faWheatSlash,
  },
  // {
  //   active: true,
  //   backendInputCategory: "labels",
  //   frontendDisplayMode: "tag",
  //   key: "lactoseFree",
  //   names: {
  //     da: "",
  //     en: "Lactose Free",
  //   },
  //   type: "labels",
  // },
  {
    active: true,
    backendInputCategory: "labels",
    frontendDisplayMode: "tag",
    key: "organic",
    names: {
      da: "Øko",
      en: "Eco",
    },

    type: "labels",
    background: `linear-gradient(114.18deg, #E30613 -19.33%, #E30613 131.99%)`,
    color: "#E30613",
  },
  {
    active: true,
    backendInputCategory: "labels",
    frontendDisplayMode: "tag",
    key: "almostOrganic",
    names: {
      da: "Delvist Øko",
      en: "Partly Eco",
    },

    type: "labels",
    background: `linear-gradient(114.18deg, #E30613 -19.33%, #E30613 131.99%)`,
    color: "#E30613",
  },
  {
    active: false,
    backendInputCategory: "piktogrammer",
    frontendDisplayMode: "tag",
    key: "popular",
    names: {
      da: "Populær",
      en: "Popular",
    },

    type: "labels",
    background: `linear-gradient(114.18deg, #ECC94B -19.33%, #D69E2E 131.99%)`,
    color: "#ECC94B",
    faIcon: faStar,
  },
  {
    active: true,
    backendInputCategory: "labels",
    frontendDisplayMode: "tag",
    key: "halal",
    names: {
      da: "Halal",
      en: "Halal",
    },

    type: "labels",
    background: `linear-gradient(114.18deg, #054020 -19.33%, #17661F 131.99%)`,
    color: "#2F855A",
  },
  {
    active: true,
    backendInputCategory: "labels",
    frontendDisplayMode: "tag",
    key: "leftovers",
    names: {
      da: "Overskudsmad",
      en: "Surplus food",
    },
    type: "labels",
    background: `linear-gradient(114.18deg, #48BB78 -19.33%, #38A169 131.99%)`,
    color: "#48BB78",
    faIcon: faHeart,
  },
  {
    active: true,
    backendInputCategory: "labels",
    frontendDisplayMode: "tag",
    key: "new",
    names: {
      da: "Nyhed",
      en: "News",
    },

    type: "labels",
    background: `linear-gradient(114.18deg, rgba(121,166,255,1) -19.33%, rgba(0,117,208,1) 131.99%)`,
    color: "rgba(121,166,255,1)",
    faIcon: faSparkles,
  },
  {
    active: true,
    backendInputCategory: "labels",
    frontendDisplayMode: "tag",
    key: "onlyToday",
    names: {
      da: "Kun i dag",
      en: "Only today",
    },
    type: "labels",
    background: `linear-gradient(114.18deg, #ECC94B -19.33%, #D69E2E 131.99%)`,
    color: "#ECC94B",
    faIcon: faCalendarDay,
  },
  {
    active: true,
    backendInputCategory: "labels",
    frontendDisplayMode: "tag",
    key: "onlyThisWeek",
    names: {
      da: "Kun i denne uge",
      en: "Only this week",
    },
    type: "labels",
    background: `linear-gradient(114.18deg, #ECC94B -19.33%, #D69E2E 131.99%)`,
    color: "#ECC94B",
    faIcon: faCalendarWeek,
  },
  {
    active: true,
    backendInputCategory: "labels",
    frontendDisplayMode: "tag",
    key: "urgentSale",
    names: {
      da: "Først til mølle",
      en: "First come, first served",
    },
    type: "labels",
    background: `linear-gradient(114.18deg, rgba(192,159,255,1) -19.33%, rgba(176,93,255,1) 131.99%)`,
    color: "rgba(192,159,255,1)",
    faIcon: faHourglassClock,
  },
  {
    active: true,
    backendInputCategory: "labels",
    frontendDisplayMode: "tag",
    key: "contactKitchen",
    names: {
      da: "Kontakt Køkken",
      en: "Contact kitchen",
    },
    type: "labels",
    background: `linear-gradient(114.18deg, rgba(192,159,255,1) -19.33%, rgba(176,93,255,1) 131.99%)`,
    color: "rgba(192,159,255,1)",
    faIcon: faPhone,
  },
  {
    active: false,
    backendInputCategory: "piktogrammer",
    frontendDisplayMode: "description",
    key: "animalCare",
    names: {
      da: "Dyrevelfærd",
      en: "Cruelty free",
    },
    type: "labels",
    background: `linear-gradient(114.18deg, #48BB78 -19.33%, #38A169 131.99%)`,
    color: "#48BB78",
    faIcon: faHandsHoldingHeart,
  },
  {
    active: false,
    backendInputCategory: "piktogrammer",
    frontendDisplayMode: "description",
    key: "sugar",
    names: {
      da: "Sukker",
      en: "Sugar",
    },
    type: "pictograms",
    faIcon: faCubesStacked,
  },
  {
    active: false,
    backendInputCategory: "piktogrammer",
    frontendDisplayMode: "description",
    key: "duck",
    names: {
      da: "And",
      en: "Duck",
    },
    type: "pictograms",
    faIcon: faDuck,
  },
  {
    active: false,
    backendInputCategory: "piktogrammer",
    frontendDisplayMode: "description",
    key: "chili",
    names: {
      da: "Chili",
      en: "Chili",
    },
    type: "pictograms",
    faIcon: faPepperHot,
  },
  {
    active: false,
    backendInputCategory: "piktogrammer",
    frontendDisplayMode: "description",
    key: "garlic",
    names: {
      da: "Hvidløg",
      en: "Garlic",
    },
    type: "pictograms",
    faIcon: faGarlic,
  },
  {
    active: false,
    backendInputCategory: "piktogrammer",
    frontendDisplayMode: "description",
    key: "turkey",
    names: {
      da: "Kalkun",
      en: "Turkey",
    },
    type: "pictograms",
    faIcon: faTurkey,
  },
  {
    active: false,
    backendInputCategory: "piktogrammer",
    frontendDisplayMode: "description",
    key: "deer",
    names: {
      da: "Krondyr",
      en: "Deer",
    },
    type: "pictograms",
    faIcon: faDeer,
  },
  {
    active: false,
    backendInputCategory: "piktogrammer",
    frontendDisplayMode: "description",
    key: "chicken",
    names: {
      da: "Kylling",
      en: "Chicken",
    },
    type: "pictograms",
  },
  {
    active: false,
    backendInputCategory: "piktogrammer",
    frontendDisplayMode: "description",
    key: "lamb",
    names: {
      da: "Lam",
      en: "Lamb",
    },
    type: "pictograms",
    faIcon: faSheep,
  },
  {
    active: false,
    backendInputCategory: "piktogrammer",
    frontendDisplayMode: "description",
    key: "beef",
    names: {
      da: "Okse",
      en: "Beef",
    },
    type: "pictograms",
    faIcon: faCow,
  },
  {
    active: false,
    backendInputCategory: "piktogrammer",
    frontendDisplayMode: "description",
    key: "cheese",
    names: {
      da: "Ost",
      en: "Cheese",
    },

    type: "pictograms",
    faIcon: faCheeseSwiss,
  },
  {
    active: false,
    backendInputCategory: "piktogrammer",
    frontendDisplayMode: "description",
    key: "pig",
    names: {
      da: "Gris",
      en: "Pig",
    },
    type: "pictograms",
    faIcon: faPig,
  },
  {
    active: false,
    backendInputCategory: "piktogrammer",
    frontendDisplayMode: "description",
    key: "cow",
    names: {
      da: "Ko",
      en: "Cow",
    },
    type: "pictograms",
    faIcon: faCow,
  },
  {
    active: false,
    backendInputCategory: "piktogrammer",
    frontendDisplayMode: "description",
    key: "goat",
    names: {
      da: "Ged",
      en: "Goat",
    },

    type: "pictograms",
  },
  {
    active: false,
    backendInputCategory: "piktogrammer",
    frontendDisplayMode: "description",
    key: "bird",
    names: {
      da: "Fugl",
      en: "Bird",
    },
    type: "pictograms",
    faIcon: faBird,
  },
  {
    active: false,
    backendInputCategory: "piktogrammer",
    frontendDisplayMode: "description",
    key: "partlyNuts",
    names: {
      da: "Spor af nødder",
      en: "Traces of nuts",
    },
    type: "pictograms",
    faIcon: faPeanuts,
  },
  {
    active: false,
    backendInputCategory: "piktogrammer",
    frontendDisplayMode: "description",
    key: "beans",
    names: {
      da: "bønner",
      en: "beans",
    },
    type: "pictograms",
    faIcon: faOlive,
  },
  {
    active: false,
    backendInputCategory: "preparation",
    frontendDisplayMode: "description",
    key: "fried",
    names: {
      da: "Stegt",
      en: "Fried",
    },
    type: "pictograms",
    faIcon: faPanFrying,
  },
  {
    active: false,
    backendInputCategory: "preparation",
    frontendDisplayMode: "description",
    key: "cooked",
    names: {
      da: "Kogt",
      en: "Boiled",
    },
    type: "pictograms",
    faIcon: faCauldron,
  },
  {
    active: false,
    backendInputCategory: "preparation",
    frontendDisplayMode: "description",
    key: "baked",
    names: {
      da: "Bagt",
      en: "Baked",
    },
    type: "pictograms",
    faIcon: faOven,
  },
  {
    active: false,
    backendInputCategory: "preparation",
    frontendDisplayMode: "description",
    key: "grilled",
    names: {
      da: "Grillet",
      en: "Grilled",
    },
    type: "pictograms",
    faIcon: faGrillHot,
  },
  {
    active: false,
    backendInputCategory: "preparation",
    frontendDisplayMode: "description",
    key: "deepFried",
    names: {
      da: "Friturestegt",
      en: "Deep fried",
    },
    type: "pictograms",
    faIcon: faSoap,
  },
  {
    active: false,
    backendInputCategory: "preparation",
    frontendDisplayMode: "description",
    key: "poached",
    names: {
      da: "Pocheret",
      en: "Poached",
    },
    type: "pictograms",
    faIcon: faPanFood,
  },
  {
    active: false,
    backendInputCategory: "preparation",
    frontendDisplayMode: "description",
    key: "steamed",
    names: {
      da: "Dampet",
      en: "Steamed",
    },
    type: "pictograms",
    faIcon: faBowlRice,
  },
  {
    active: false,
    backendInputCategory: "preparation",
    frontendDisplayMode: "description",
    key: "braised",
    names: {
      da: "Braiseret",
      en: "Braised",
    },
    type: "pictograms",
    faIcon: faPotFood,
  },
];
