import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { getAttributes, GetAttributesProps } from "./getAttributes";

interface ReturnProps {
  backendInputCategory: string;
  frontendDisplayMode: "tag" | "description" | "hidden";
  name: string;
  key: string;
  names: {
    da: string;
    en: string;
  };
  active: boolean;
  type: "allergens" | "labels" | "pictograms";
  background?: string;
  color?: string;
  faIcon?: IconDefinition;
}

export const getAttribute = (
  props: GetAttributesProps & { key: string }
): Partial<ReturnProps> =>
  getAttributes(props).find((a) => a.key === props.key) || {};
