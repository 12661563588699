import { Supplier } from "@kanpla/types";
import { i18n as I18n } from "i18next";
import { AttributeData, attributesData } from "./attributesData";

export type GetAttributesProps = {
  supplier: Supplier;
  /** Optional, to also get inactive values */
  getAll?: boolean;
  /** Optional to get localized name */
  i18n?: I18n;
};

/** Takes in supplier's customization, and returns a filtered list of attributes with adjusted values by the customization */

export const getAttributes = ({
  supplier,
  getAll = false,
  i18n,
}: GetAttributesProps) => {
  const customData = attributesData.map((defaultData) => {
    const { key, type, names } = defaultData;
    const customizationData: Partial<AttributeData> =
      supplier?.custom?.rules?.[type]?.[key];

    // Currently supporting 'da' | 'en'
    const strippedCode = i18n?.resolvedLanguage?.substring(0, 2);
    const supportedLanguages = ["da", "en"];
    const strippedCodeValid = supportedLanguages.includes(strippedCode);
    const finalCode = strippedCodeValid ? strippedCode : "da";
    const name: string = names?.[finalCode] || key;

    // Merge custom and default data
    const finalValue = {
      ...defaultData,
      ...customizationData,
      backendInputCategory:
        customizationData?.backendInputCategory ||
        defaultData?.backendInputCategory,
      frontendDisplayMode:
        customizationData?.frontendDisplayMode ||
        defaultData?.frontendDisplayMode,
      name,
    };

    return finalValue;
  });

  const onlyActive = customData.filter((value) => value.active);

  return getAll ? customData : onlyActive;
};
